.typo9 {
  white-space: nowrap;
  margin-left: 5%;
  margin-top: 5%;
}

.contact-from {
  padding: 6rem;
}
.contact-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 4rem;
}
.contact-form-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 44px;
  font-weight: 600;
  color: rgb(32, 106, 143);
  text-shadow: 2px 2px 5px rgb(176, 175, 175);
  margin-top: 20px;
}
.contact-form-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
}
.contact-box {
  display: flex;
  margin-top: 2rem;
  gap: 1rem;
  align-items: baseline;
}
.more-info p {
  font-size: 18px;
  font-size: 19px;
  margin-bottom: 3rem;
  color: rgb(53, 53, 53);
  font-weight: 500;
}
.contact-box-icons {
  color: #0a446c;
  svg {
    transform: scale(1);
  }
}
.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.input-field {
  padding: 10px 2rem;
  font-size: 14px;
  color: rgb(123, 123, 123);
  cursor: pointer;
  border: none;
  margin-bottom: 15px;
  border-radius: 4px;
}
.form-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
.contact-form-message {
  width: 100%;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  padding: 10px;
}
.submit-button {
  padding: 12px 29px;
  background-color: #0a446c;
  color: #fff;
  font-size: 17px;
  border-radius: 0.25em;
  cursor: pointer;
  margin-top: 10px;
  border: none;
}

@media screen and (max-width: 768px) {
  .typo9 {
    margin-top: 60%;
    margin-left: 2rem;
  }

  .contact-container {
    flex-direction: column;
  }
  .contact-form-heading {
    display: inline-block;
    margin-top: 10%;
    justify-content: center;
    text-align: center;
    font-size: 30px;
  }
  .contact-form-info {
    align-items: center;
  }
  .contact-form-info p {
    text-align: center;
  }
  .contact-box {
    flex-direction: column;
    align-items: center;
    margin-top: 0%;
  }
  .form {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-input {
    width: 20px;
    flex-direction: column;
  }
  .input-field {
    padding: 10px 1rem;
    width: 90%;
  }
  .form-input {
    width: 100%;
  }
  .input-message {
    width: 90%;
  }
  .contact-form-btn {
    width: 100%;
  }
  .submit-button {
    padding: 12px 20px;
    width: 100%;
  }
}

/* NEWWWWW */

.ch-contact {
  padding: 0px 20px 120px 20px;
  overflow-x: hidden;
}
.ch-honeycomb-bg1 {
  background-image: url("https://jrb.ae/wp-content/themes/jrb/assets/images/honeycomb1.png");
  background-attachment: fixed;
  background-position: right;
  background-repeat: no-repeat;
  background-size: 20%;
}
.ch-section {
  position: relative;
}
@media only screen and (max-width: 1536px) {
  .ch-maxwd {
    max-width: 1440px;
  }
}
.ch-maxwd {
  position: relative;
  max-width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  width: 100%;
}
.ch-contact .ch-content {
  color: #ffffff;
  padding: 5% 7%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 0px;
}
.ch-grey-bg {
  background-color: #163c4f;
}
@media (min-width: 576px) {
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (max-width: 776px) {
  .ch-grey-bg h2 {
    font-size: 22px;
  }
}
.ch-contact .ch-data {
  color: #ffffff;
  padding: 5% 7%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 0px;
}
.ch-blue-bg {
  background-color: #1e88bd;
}
.ch h2 {
  font-size: 32px;
  line-height: 39px;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  padding: 0px;
  margin: 0px;
}

/* .ch-contact .ch-content p {
  padding-bottom: 10px;
} */
.ch-content a {
  color: #ffffff;
}
.ch p {
  margin: 0px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 24px;
}
.ch-contact .ch-content ul {
  display: block;
}

.ch-contact .ch-content ul li {
  position: relative;
  display: block;
  padding: 10px 20px 10px 30px;
  color: #ffffff;
}
.ch li {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 24px;
}
.ch-contact .ch-content ul li::before {
  position: absolute;
  content: "\f101";
  font: normal normal normal 14px / 1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 12px;
  left: 0px;
  color: #0093d1;
  font-size: 20px;
  line-height: 20px;
}
.ch-contact .ch-content ul li p:last-child {
  padding-bottom: 0px;
}
.ch-contact .ch-content ul li p {
  color: #ffffff;
}
.ch p {
  margin: 0px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 24px;
}
.screen-reader-response {
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  word-wrap: normal !important;
}
.ch-contact .ch-data .ch-form .ch-split {
  display: inline-block;
  width: 46%;
  margin: 0px -2px;
  /* padding: 20px 20px; */
  margin-right: 15px;
}
.ch-contact .ch-data .ch-form .ch-phone {
  display: inline-block;
  width: 50%;
  margin: 0px -2px;
  /* padding: 20px 20px; */
}
.ch span {
  margin: 0px;
  padding: 0px;
  font-size: inherit;
  line-height: inherit;
}
.init input {
  margin: 0px;
  padding: 0px;
  font-family: "Open Sans", sans-serif;
  outline: none;
  background: none;
  border: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  font-size: 16px;
  line-height: 24px;
}
.ch-contact .ch-data .ch-form input {
  color: #ffffff;
  padding: 10px 0px;
  width: 100%;
  border-radius: 0px;
  border-bottom: 2px solid #ffffff;
}

.ch-contact .ch-data .ch-form textarea {
  color: #ffffff;
  padding: 10px 0px;
  width: 100%;
  border-radius: 0px;
  border-bottom: 2px solid #ffffff;
}
.init textarea {
  resize: none;
}
textarea {
  margin: 0px;
  padding: 0px;
  font-family: "Open Sans", sans-serif;
  outline: none;
  background: none;
  border: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="text"] {
  color: #ffffff;
}

input::placeholder {
  color: white !important;
}
textarea::placeholder {
  color: white !important;
}

input {
  color: #ffffff;
}
textarea {
  color: #ffffff;
}

input:focus {
  background: transparent !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-radius: 0 !important;
  outline: none !important;
  color: #ffffff;
}

.ch-contact .ch-data .ch-form input[type="submit"] {
  width: auto;
  padding: 10px 20px;
  color: #ffffff;
  border: none;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 0px;
  background-color: #163c4f;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  cursor: pointer;
}

.reach_us_box {
  display: flex;
  gap: 15px;
  align-items: baseline;
  margin-bottom: 15px;
  svg {
    height: 8px;
  }
}
.phone-input {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  input {
    color: #1e88bd;
    height: 60px;
  }
  .react-international-phone-input-container {
    width: 100%;
    .react-international-phone-input {
      width: 100%;
      background: #1e88bd;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-radius: 0;
      color: white !important;
      height: 50px;
      font-size: 15px;
    }
    .react-international-phone-country-selector {
      .react-international-phone-country-selector-button {
        background: #1e88bd;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-radius: 0;
        height: 50px;
        border-bottom: 2px solid black !important ;
      }
    }
  }
}
