.typo6 {
  white-space: nowrap;
  margin-left: 5%;
  margin-top: 5%;
}

.corporation-container {
  margin: 4rem;
  margin-top: 6rem;
}
.corporation-img img {
  border-radius: 4px;
  box-shadow: 1px 4px 10px rgb(160, 160, 160);
}
.corporation-card-img img {
  border-radius: 8px;
  display: inline-block;
  max-width: 100%;
  height: auto;
  max-height: 14rem;
  box-shadow: 1px 4px 10px rgb(160, 160, 160);
  opacity: 1;
  transition: 1s ease;
}
.corporation-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.corporation-card-img img:hover {
  opacity: 0.5;
  transition: 1s ease;
}
.corporation-card {
  overflow: hidden;
  position: relative;
  margin-bottom: 2rem;
  width: 450px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 8px;
  cursor: pointer;
}
.corporation-info {
  align-items: center;
  justify-content: center;
  padding-left: 35px;
  padding-right: 35px;
  position: relative;
  padding-bottom: 35px;
}
.corporation-para {
  width: 400px;
}
.corporation-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  padding-left: 4rem;
  padding-right: 4rem;
}
.corporation-heading {
  font-size: 28px;
  color: rgb(21, 70, 95);
  cursor: pointer;
  margin-bottom: 6px;
}

@media screen and (max-width: 768px) {
  .typo6 {
    margin-top: 60%;
    margin-left: 2rem;
  }
  .info h1 {
    font-size: 20px;
    text-align: right;
    color: rgb(250, 250, 250);
    font-weight: 600;
    padding-right: 40px;
    padding-top: 14%;
    display: block;
  }
  .corporation-container {
    flex-direction: column;
    gap: 10px;
    display: block;
  }
  .info-paragraph {
    width: 100%;
  }
  .corporation-para {
    width: 100%;
  }
  .corporation-cards {
    grid-template-columns: repeat(1, 1fr);
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .corporation-card {
    width: 100%;
  }
  .corporation-heading {
    font-size: 20px;
  }
  .corporation-img img {
    /* width: 140%;
    margin-left: -20%; */
    display: none;
  }
}
