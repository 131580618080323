.info {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100vh;
  margin: 0%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transition-property: background-image;
  transition-duration: 1s;
  transition-timing-function: linear;
  align-items: end;
  animation: 5s ease-in-out infinite;
  @media (max-width: 992px) {
    display: none;
  }
}
@keyframes change {
  0% {
    background-image: url("../Images/bgimg.jpg");
  }
  25% {
    background-image: url("../Images//bckimg2.jpg");
  }
  50% {
    background-image: url("../Images/bgimg.jpg");
  }
  70% {
    background-image: url("../Images/bckimg2.jpg");
  }
  100% {
    background-image: url("../Images/bgimg.jpg");
  }
}
.info-mobile {
  display: none;
  @media (max-width: 992px) {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 100vh;
    margin: 0%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    transition-property: background-image;
    transition-duration: 1s;
    transition-timing-function: linear;
    align-items: end;
    animation: 5s change infinite;
  }
}

.home_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: -15px;
  margin-left: -15px;
}
.typo {
  white-space: nowrap;
  margin-left: 5%;
  margin-top: 5%;
}

.btn {
  height: 50px;
  padding: 10px 20px;
  color: rgb(250, 250, 250);
  background-color: #0a446c;
  font-size: 18px;
  font-weight: 500;
  border-radius: 5px;
  border: none;
}

.btn:hover {
  background-color: rgb(49, 193, 49);
}

.home-box-icons svg {
  transform: scale(1);
}
.box-icons {
  @media (max-width: 992px) {
    width: 75%;
  }
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 60px;
  margin-left: 2rem;
  margin-right: 2rem;
  align-items: center;
  @media (max-width: 992px) {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
.home_para {
  display: flex;
  flex-direction: column;
  width: 700px;
}
.home_heading {
  color: rgb(32, 106, 143);
  font-size: 45px;
  text-transform: uppercase;
  font-weight: 600;
  text-shadow: 2px 2px 5px rgb(176, 175, 175);
}
.intro {
  color: rgb(67, 67, 67);
  font-size: 24px;
}
.home_para p {
  color: rgb(99, 98, 98);
  font-size: 18px;
  margin-bottom: 2rem;
  font-family: serif;
  text-align: justify;
}
.btn-2 {
  width: 30%;
  padding: 11px 18px;
  font-size: 19px;
  font-weight: 500;
  color: #fff;
  border: none;
  border-radius: 4px;
  background-color: rgb(35, 155, 215);
  cursor: pointer;
}
.btn-2:hover {
  background-color: #0a446c;
  border: 1px solid rgb(51, 50, 50);
}
.image img {
  width: 600px;
  border-radius: 2px;
  box-shadow: 1px 4px 10px rgb(160, 160, 160);
}

/* core values  styling section 2 */

.sec-2 .main-heading {
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  color: rgb(32, 106, 143);
  margin-top: 4rem;
  text-shadow: 2px 2px 5px rgb(176, 175, 175);
}
.item span {
  color: #0a446c;
}

.team {
  height: 120px;
  width: 100px;
  line-height: 110px;
}
.value-list .heading-2 {
  font-size: 25px;
  color: rgb(50, 50, 50);
}
.value-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  gap: 50px;
  margin-top: 5rem;
  overflow-x: auto;
  margin-left: 3rem;
  margin-right: 3rem;
}
.value-list p {
  font-size: 16px;
  color: rgb(99, 98, 98);
}
.value-list .item {
  background-color: white;
  border-radius: 2rem;
}
/* styling the business info box section 4*/

.tital-heading {
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  color: rgb(32, 106, 143);
  margin-top: 5rem;
  margin-bottom: 4rem;
  text-shadow: 2px 2px 5px rgb(176, 175, 175);
}

.box-content p {
  font-size: 18px;
  color: rgb(78, 78, 78);
  text-align: start;
}
.info-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}
.info-box svg {
  padding-top: 6px !important;
}
.info-box::before {
  content: "";
  background-image: url("../Images//box-img.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.2;
  position: absolute;
  height: 100%;
  width: 100%;
}
.box {
  width: 20%;
  display: flex;
  flex-direction: column;
  line-height: 30px;
  justify-content: center;
  align-items: center;
}

/* styling the team card section 3*/

.card-sec h1 {
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
  font-size: 40px;
  /* color: rgb(32, 106, 143); */
  text-shadow: 2px 2px 5px rgb(176, 175, 175);
}
.cards {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 2rem;
  margin-right: 2rem;
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 32%;
  height: 10%;
  height: fit-content;
  text-align: center;
  margin-bottom: 4rem;
  background-color: rgb(241, 243, 245);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  border-radius: 25px;
  padding: 2rem;
}

.card:hover {
  border: 1px solid rgb(152, 150, 150);
}
.card-img img {
  border-radius: 50%;
  height: 250px;
  width: 250px;
}
.card-info .card-para {
  color: rgb(78, 78, 78);
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  text-align: start;
}
.card-info .card-heading {
  font-size: 28px;
  color: rgb(21, 70, 95);
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .info {
    padding-top: 20%;
    margin-top: -220%;
    text-size-adjust: 20px;
  }
  .typo {
    white-space: normal;
    margin-top: 50%;
    margin-left: 30%;
    display: inline;
  }
  .content {
    display: block;
    margin-top: 20px;
  }
  .content img {
    display: none;
  }

  .home_para {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .home_heading {
    font-size: 35px;
  }
  .intro {
    font-size: 20px;
  }
  .home_para p {
    font-size: 16px;
    font-family: serif;
    text-align: justify;
  }
  .btn-2 {
    width: 90%;
    padding: 11px 18px;
    font-size: 19px;
    font-weight: 500;
    color: #fff;
    border: none;
    border-radius: 4px;
    background-color: rgb(35, 155, 215);
    cursor: pointer;
  }
  .btn-2:hover {
    background-color: #0a446c;
    border: 1px solid rgb(51, 50, 50);
  }
  .image img {
    width: 100%;
    border-radius: 2px;
    box-shadow: 1px 4px 10px rgb(160, 160, 160);
    margin-top: 2rem;
  }
  .sec-2 .main-heading {
    text-align: center;
    font-size: 35px;
    font-weight: 600;
    color: rgb(32, 106, 143);
    margin-top: 4rem;
    text-shadow: 2px 2px 5px rgb(176, 175, 175);
  }
  .value-list .heading-2 {
    font-size: 25px;
    color: rgb(50, 50, 50);
  }
  .value-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 50px;
    margin-top: 5rem;
    overflow-x: auto;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .value-list p {
    font-size: 16px;
    color: rgb(99, 98, 98);
  }
  .tital-heading {
    text-align: center;
  }
  .card {
    width: 100%;
    margin-left: -1rem;
    justify-content: center;
    margin-bottom: 2rem;
    background-color: #fafafa;
    padding: 1.5rem;
    border-radius: 10px;
    box-sizing: border-box;
  }
  .sec-4 .box-content p {
    font-size: 13px;
    padding: 0% 5% 0% 5%;
    color: rgb(0, 0, 0);
    text-align: center;
  }
  .box {
    display: flex;
    flex-direction: column;
    line-height: 20px;
    justify-content: flex-start;
    align-items: center;
  }

  .sec-3 .cards {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .card-sec h1 {
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 4rem;
    font-size: 35px;
    /* color: rgb(32, 106, 143); */
    text-shadow: 2px 2px 5px rgb(176, 175, 175);
  }

  .btn {
    display: none;
  }
}
/* social Icon css */
.wpsr-follow-icons.sr-fb-vl {
  text-align: center;
  /* line-height: 1.5Q; */
  position: sticky;
  /* right: -5rem; */
  /* transform: rotate(90deg); */
  /* margin-top: 10rem; */
  @media (max-width: 992px) {
    position: fixed;
  }
}
.sr-fb-rm {
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
.wpsr-follow-icons {
  position: fixed;
  z-index: 999999;
  line-height: 0;
}
.socializer {
  padding: 0;
  margin: 0;
  display: inline-flex;
  flex-wrap: wrap;
  position: absolute;
  right: -4rem;
  transform: rotate(90deg);
}
.wpsr-follow-icons.sr-fb-vl {
  text-align: center;
  line-height: 1.5Q;
}
.sr-vertical.sr-pad > * {
  margin: 0 0 0.5em 0;
}
.sr-vertical.sr-32px > * {
  width: 46px;
}
.socializer > * {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-block;
  position: relative;
  line-height: 0;
}
.wpsr-follow-icons.sr-fb-vl {
  text-align: center;
  line-height: 1.5Q;
}
.socializer .sr-facebook a,
.socializer .sr-facebook a:visited {
  color: #ffffff;
  border-color: #0866ff;
  background-color: #0866ff;
}
.sr-vertical a {
  flex-direction: column;
}
.sr-circle a {
  border-radius: 50%;
}
.sr-32px a {
  width: 32px;
  height: 32px;
  font-size: 16px;
}
.sr-32px img {
  width: 48px;
  height: 48px;
  font-size: 16px;
  transform: rotate(-90deg);
}
.socializer a {
  font-family: sans-serif !important;
  display: inline-flex;
  border: 0;
  text-align: center;
  text-decoration: none;
  font-size: 8px;
  border-style: solid;
  box-sizing: content-box;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
  transition-timing-function: ease-out;
}

.socializer a > * {
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  margin: 0;
}
.fab {
  font-weight: 400;
}

.fa-facebook-f:before {
  content: "\f09a";
}

.rightIcon {
  position: absolute;
  right: 5rem;
  transform: translate(50%, 50%);
  height: 100%;
  opacity: 1;
  /* //need to change */
}
.leftIcon {
  position: absolute;
  left: 0;
  padding-left: 1rem;
  transform: translate(50%, 50%);
  height: 100%;
  opacity: 1; /* //need to change */
}
.leftIcon svg {
  color: rgb(255, 255, 255);
  position: absolute;
  left: 0;
  right: 0;
  transform: translate(28px, 20px);
  font-size: 1.5rem;
}
.rightIcon svg {
  color: rgb(255, 255, 255);
  position: absolute;
  left: 0;
  right: 0;
  transform: translate(28px, 20px);
  font-size: 1.5rem;
}

.rightIconImageBox {
  background: rgba(0, 0, 0, 0.1);
  width: 70px;
  height: 70px;
  position: relative;
  border-radius: 50%;
}
.leftIconImageBox {
  background: rgba(0, 0, 0, 0.1);
  width: 70px;
  height: 70px;
  position: relative;
  border-radius: 50%;
}
.info:hover .rightIcon,
.info:hover .leftIcon {
  opacity: 1;
}

.home-hexagon {
  transform: scale(2);
  width: 190px;
  -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  @media (max-width: 992px) {
    width: 100%;
    -webkit-clip-path: none;
    clip-path: none;
    transform: scale(1);
  }
}

.home-hexagon img {
  object-fit: fill;
  width: 100% !important;
}

.home-prish-hexagon {
  transform: scale(1.6);
  width: 190px;
  -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  @media (max-width: 992px) {
    width: 100%;
    -webkit-clip-path: none;
    clip-path: none;
    transform: scale(1);
  }
}

.home-prish-hexagon img {
  object-fit: fill;
  width: 100% !important;
}

.arrow-box {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

/* vision */
.arrow-pointer {
  width: 100%;
  height: 190px;
  background: rgb(166 225 255);
  position: relative;

  /* &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0%;
    width: 0;
    height: 0;
    border-left: 75px solid rgb(250, 247, 247);
    border-top: 75px solid transparent;
    border-bottom: 75px solid transparent;
  } */

  &:before {
    content: "";
    position: absolute;
    right: -100px;
    bottom: -16%;
    width: 0;
    height: 0;
    border-left: 125px solid rgb(166 225 255);
    border-top: 125px solid transparent;
    border-bottom: 125px solid transparent;
  }
}
.arrow-pointer span {
  position: absolute;
  left: 15px;
  top: 5%;
  color: rgb(0, 0, 0);
  font-size: 25px;
  text-transform: uppercase;
  font-family: serif;
  margin-bottom: 15px;
  @media (max-width: 992px) {
    right: 27%;
    top: 17%;
    font-size: 22px;
  }
}

/* revert */
.arrow-pointer-left {
  width: 100%;
  height: 190px;
  background: rgb(166 225 255);
  position: relative;
  transform: rotate(180deg);
  margin-top: 55px;
  margin-left: 30px;
  margin-bottom: 5em;
  margin-top: 13em;
  /* &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 75px solid rgb(250, 247, 247);
    border-top: 75px solid transparent;
    border-bottom: 75px solid transparent;
  } */

  &:before {
    content: "";
    position: absolute;
    right: -102px;
    bottom: -16%;
    width: 0;
    height: 0;
    border-left: 125px solid rgb(166 225 255);
    border-top: 125px solid transparent;
    border-bottom: 125px solid transparent;
  }
}
.sec4-content {
  width: 90%;
  display: flex;
  margin-top: 2rem;
}
.arrow-pointer-left span {
  position: absolute;
  left: 2rem;
  bottom: 10px;
  color: rgb(0, 0, 0);
  font-size: 25px;
  text-transform: uppercase;
  font-family: serif;
  transform: rotate(180deg);
  @media (max-width: 992px) {
    right: 16%;
    top: 10%;
    font-size: 22px;
  }
}
.arrow-box {
}

/* Ribbon Header */
.ribbon {
  width: 400px;
  height: 60px;
  margin: 100px auto 0px;
  position: relative;
  color: #fff;
  font: 22px/60px sans-serif;
  text-align: center;
  text-transform: uppercase;
  background: #163c4f;
  margin-bottom: 2rem;
  -webkit-animation: main 250ms;
  -moz-animation: main 250ms;
  -ms-animation: main 250ms;
  animation: main 250ms;
  @media (max-width: 992px) {
    width: 250px;
    font: 12px/60px sans-serif;
  }
}

.ribbon i {
  position: absolute;
}

.ribbon i:first-child,
.ribbon i:nth-child(2) {
  position: absolute;
  left: -20px;
  bottom: -20px;
  z-index: -1;
  border: 20px solid transparent;
  border-right-color: #163c4f;

  -webkit-animation: edge 500ms;
  -moz-animation: edge 500ms;
  -ms-animation: edge 500ms;
  animation: edge 500ms;
}

.ribbon i:nth-child(2) {
  left: auto;
  right: -20px;
  border-right-color: transparent;
  border-left-color: #163c4f;
}

.ribbon i:nth-child(3),
.ribbon i:last-child {
  width: 20px;
  bottom: -20px;
  left: -60px;
  z-index: -2;
  border: 30px solid #163c4f;
  border-left-color: transparent;

  -webkit-animation: back 600ms;
  -moz-animation: back 600ms;
  -ms-animation: back 600ms;
  animation: back 600ms;

  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.ribbon i:last-child {
  bottom: -20px;
  left: auto;
  right: -60px;
  border: 30px solid #163c4f;
  border-right-color: transparent;

  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
}

/* animations */

@-webkit-keyframes main {
  0% {
    -webkit-transform: scaleX(0);
  }
  100% {
    -webkit-transform: scaleX(1);
  }
}

@-webkit-keyframes edge {
  0%,
  50% {
    -webkit-transform: scaleY(0);
  }
  100% {
    -webkit-transform: scaleY(1);
  }
}

@-webkit-keyframes back {
  0%,
  75% {
    -webkit-transform: scaleX(0);
  }
  100% {
    -webkit-transform: scaleX(1);
  }
}

@-moz-keyframes main {
  0% {
    -moz-transform: scaleX(0);
  }
  100% {
    -moz-transform: scaleX(1);
  }
}

@-moz-keyframes edge {
  0%,
  50% {
    -moz-transform: scaleY(0);
  }
  100% {
    -moz-transform: scaleY(1);
  }
}

@-moz-keyframes back {
  0%,
  75% {
    -moz-transform: scaleX(0);
  }
  100% {
    -moz-transform: scaleX(1);
  }
}

@keyframes main {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

@keyframes edge {
  0%,
  50% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes back {
  0%,
  75% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

.vision-content {
  position: absolute;
  right: 5%;
  top: 30%;
  left: 0%;
  color: rgb(0, 0, 0);
  padding-left: 20px;
  font-size: 13px;
  font-family: serif;
  max-height: 140px;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vision-content-left {
  position: absolute;
  right: 10%;
  bottom: 25%;
  left: 5%;
  color: rgb(0, 0, 0);
  padding-left: 20px;
  font-size: 13px;
  font-family: serif;
  transform: rotate(-180deg);
  max-height: 140px;

  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 992px) {
  .socializer {
    padding: 0;
    margin: 0;
    display: inline-flex;
    flex-wrap: nowrap;
    position: absolute;
    right: -6rem;
    transform: rotate(90deg);
  }
}

/* First template */
.mission-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
  text-align: center;
}

.mission-title h2 {
  /* font-size: 28px; */
  margin-bottom: 5px;
}

.mission-title p {
  font-size: 16px;
  color: #7d7d7d; /* Gray color */
  margin-bottom: 20px;
}

.mission-cards {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.mission-card {
  background: #fff;
  padding: 50px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 30%;
  position: relative;
  text-align: center;
}

.mission-card h3 {
  font-size: 18px;
  margin: 20px 0 10px;
}

.mission-card p {
  font-size: 14px;
  color: #7d7d7d;
}

.mission-card .icon {
  font-size: 40px;
  margin-bottom: 10px;
}

.mission-card.vision {
  border-top: 5px solid #ffa500; /* Orange */
}

.mission-card.mission {
  border-top: 5px solid #ff4500; /* Red */
}

.mission-card.strategy {
  border-top: 5px solid #008080; /* Teal */
}

/* Second Template */
.mv-section {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 40px;
  text-align: center;
  font-family: Arial, sans-serif;
}

.mv-item {
  width: 30%;
}

.mv-icon-container {
  width: 100px;
  height: 100px;
  margin: 0 auto 20px auto;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.mv-icon {
  font-size: 40px;
  position: absolute;
}

.mission-icon {
  background: linear-gradient(135deg, #6f42c1, #007bff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.vision-icon {
  background: linear-gradient(135deg, #1dc4e9, #17a2b8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.values-icon {
  background: linear-gradient(135deg, #20c997, #28a745);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.mv-title {
  font-size: 22px;
  margin: 10px 0;
}

.mission-title {
  color: #6f42c1;
}

.vision-title {
  color: #17a2b8;
}

.values-title {
  color: #28a745;
}

.mv-description {
  font-size: 14px;
  color: #7d7d7d;
  margin-top: 15px;
  padding: 0 10px;
}

@media (max-width: 992px) {
  .mission-cards {
    display: block;
  }
  .mission-card {
    width: 100%;
    margin-bottom: 10px;
  }
}
