.typo5 {
  white-space: nowrap;
  margin-left: 5%;
  margin-top: 5%;
}

.corporate-sec-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 3rem;
}
@keyframes slideOut {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
.corporate-img {
  width: 27%;
  @media (max-width: 992px) {
    width: 100%;
    height: auto;
  }
}
.corporate-img.visible {
  opacity: 1;
  animation: slideOut 1s linear;
}
@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
.audit-header {
  font-size: 45px;
  color: #163c4f;
  text-shadow: 2px 2px 5px rgb(176, 175, 175);
  font-weight: 600;
  font-family: serif;
  text-transform: uppercase;
  transition: opacity 0.5s ease-in;
}
.audit-header-visible {
  opacity: 1;
  animation: slideIn 2s linear;
}
.corporate-img img {
  border-radius: 4px;
  box-shadow: 1px 4px 10px rgb(160, 160, 160);
}
.corporate-card-img img {
  border-radius: 8px;
  display: inline-block;
  max-width: 100%;
  height: auto;
  box-shadow: 1px 4px 10px rgb(160, 160, 160);
  opacity: 1;
  transition: 1s ease;
  height: 30vh;
  max-height: 30vh;
}
.corporate-card-img img:hover {
  opacity: 0.5;
  transition: 1s ease;
}
.corporate-card {
  overflow: hidden;
  position: relative;
  margin-bottom: 2rem;
  width: 450px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);

  background-color: #fff;
  border-radius: 8px;
  cursor: pointer;
}
.corporate-info {
  align-items: center;
  justify-content: center;
  padding-left: 35px;
  padding-right: 35px;
  position: relative;
  padding-bottom: 35px;
}
.corporate-sec-2-heading {
  display: flex;
  justify-content: center;
  margin-top: 6rem;
  margin-bottom: 4rem;
  align-items: center;
  font-size: 38px;
  color: rgb(32, 106, 143);
  text-shadow: 2px 2px 5px rgb(176, 175, 175);
  font-weight: 600;
}
.corporate-para {
  width: 400px;
}
.corporate-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  padding-left: 4rem;
  padding-right: 4rem;
}
.corporate-heading {
  font-size: 28px;
  color: rgb(21, 70, 95);
  cursor: pointer;
  margin-bottom: 6px;
}

@media screen and (max-width: 768px) {
  .typo5 {
    white-space: normal;
    margin-top: 60%;
    margin-left: 2rem;
  }
  .corporate-container {
    flex-direction: column;
    gap: 10px;
  }
  .corporate-sec-1 {
    padding-left: 1rem;
    padding-right: 1rem;
    flex-direction: column;
    margin-top: 4rem;
  }

  .corporate-img img {
    width: 100%;
  }
  .corporate-sec-2-heading {
    font-size: 20px;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 4rem;
  }
  .corporate-cards {
    grid-template-columns: repeat(1, 1fr);
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .corporate-para {
    width: 100%;
  }
  .corporate-heading {
    font-size: 24px;
  }
  .corporate-card {
    width: 100%;
  }
  .corporate-info {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
  .corporate-card-img img {
    width: 100%;
  }
}

.hexagon {
  transform: scale(1.5);
  width: 45%;
  -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
}
@media (max-width: 992px) {
  .hexagon {
    width: 100%;
    -webkit-clip-path: none;
    clip-path: none;
    transform: scale(1);
  }
  .audit-header {
    font-size: 30px;
    margin-top: 10px;
  }
}
.hexagon1 {
  width: 45%;
  transform: scale(1.5);
  -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  @media (max-width: 992px) {
    display: none;
  }
}
.hexagon img {
  object-fit: cover;
}
.hexagon1 img {
  object-fit: cover;
}

.hexagon2 img {
  object-fit: cover;
}
.hexagon2 {
  width: 45%;
  transform: scale(1.5);
  -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  @media (max-width: 992px) {
    display: none;
  }
}
.nextConatainer {
  width: 100%;
  display: flex;
  justify-content: end;
}

.read-more-btn-1 {
  font-size: 15px;
  font-weight: 500;
  color: #0269ff;
  border: none;
  background-color: rgb(245 245 245);
  cursor: pointer;
  margin-left: 7px;
}
