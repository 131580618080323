.typo7 {
  white-space: nowrap;
  margin-left: 5%;
  margin-top: 5%;
}

.taxation-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 4rem;
}

.taxation-2-container p {
  font-size: 16px;
  font-weight: 400;
  position: relative;
  color: rgb(99, 98, 98);
  line-height: 26px;
  font-family: serif;
  width: 60%;
}
.taxation-2-container h5 {
  font-size: 19px;
}
.taxation-2-container ul {
  margin-left: 20px;
}
.taxation-2-container li {
  color: black;
  margin-bottom: 10px;
}
.taxation-2-container h1 {
  display: flex;
  align-items: center;
  margin-top: 4rem;
  font-size: 30px;
  color: rgb(32, 106, 143);
  text-shadow: 2px 2px 5px rgb(176, 175, 175);
  font-weight: 600;
}
.taxation-2-container {
  margin-left: 4rem;
}
.taxation-img {
  border-radius: 4px;
  box-shadow: 1px 4px 10px rgb(160, 160, 160);
}

@media screen and (max-width: 768px) {
  .typo7 {
    margin-top: 60%;
    margin-left: 2rem;
  }
  .taxation-info-container {
    padding-left: 1rem;
    padding-right: 1rem;
    flex-direction: column;
    gap: 20px;
  }

  .taxation-2-container {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 0;
  }
  .taxation-2-container p {
    width: 100%;
  }
  .taxation-img img {
    width: 100%;
  }
}
