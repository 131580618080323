.cky-banner-bottom {
  bottom: 0;
  left: 0;
}
.cky-consent-container {
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  z-index: 9999999;
}
.cky-consent-container .cky-consent-bar {
  background: #ffffff;
  border: 1px solid;
  padding: 16.5px 24px;
  box-shadow: 0 -1px 10px 0 #acabab4d;
}
.cky-notice .cky-title {
  color: #212121;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin: 0 0 12px 0;
}
.cky-notice-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  @media screen and (max-width: 768px) {
    display: block;
  }
}
.cky-notice-des {
  color: #212121;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}
.cky-notice-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
}
.cky-notice-des P:last-child {
  margin-bottom: 0;
}
.cky-notice-des * {
  font-size: 14px;
}
.cky-notice-btn-wrapper .cky-btn {
  text-shadow: none;
  box-shadow: none;
}
.cky-btn-reject {
  background: transparent;
  border: 2px solid #1863dc;
}
.cky-btn {
  font-size: 14px;
  font-family: inherit;
  line-height: 24px;
  padding: 8px 27px;
  font-weight: 500;
  margin: 0 8px 0 0;
  border-radius: 2px;
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
  text-transform: none;
  min-height: 0;
}
