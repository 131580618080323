.typo8 {
  white-space: nowrap;
  margin-left: 5%;
  margin-top: 5%;
}
@media screen and (max-width: 600px) {
  .typo8 {
    white-space: nowrap;
    margin-left: 5%;
    margin-top: 50%;
  }
}
.team-content {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  /* background-color: black; */
  background-image: url("./Images//team-bg.jpg");
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  padding-top: 30px;
  padding-left: 5rem;
  padding-right: 5rem;
  position: relative;
}

.para {
  display: flex;
  flex-direction: column;
  width: 700px;

  justify-content: center;
}
.para p {
  color: white;
  font-size: 18px;
  margin-bottom: 2rem;
  text-align: center;
  font-family: serif;
}
.heading {
  color: white;
  font-size: 45px;
  font-weight: 600;
  text-shadow: 2px 2px 5px rgb(176, 175, 175);
  display: flex;
  justify-content: center;
  text-transform: capitalize !important;
  font-family: serif;
}

.intro {
  color: rgb(67, 67, 67);
  font-size: 24px;
  font-family: serif;
}
.image img {
  width: 600px;
  border-radius: 10rem;
  box-shadow: 1px 4px 10px rgb(160, 160, 160);
}
@media screen and (max-width: 768px) {
  .team-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .para {
    display: flex;
    flex-direction: column;
    width: 100%;

    justify-content: center;
  }
  .para p {
    font-size: 16px;
  }
  .heading {
    font-size: 35px;
  }
  .image img {
    width: 100%;
    border-radius: 10rem;
    box-shadow: 1px 4px 10px rgb(160, 160, 160);
    margin-top: 2rem;
  }
  .intro {
    font-size: 20px;
    text-transform: capitalize;
  }
}
