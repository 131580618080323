/* styling the section 2 company information */
.typo2 {
  white-space: nowrap;
  margin-left: 5%;
  margin-top: 5%;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 80px;
}
.row-info h1 {
  font-size: 44px;
  color: #163c4f;
  text-shadow: 2px 2px 5px rgb(176, 175, 175);
  font-weight: 600;
}
.sec2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 6rem;
  position: relative;
  margin-top: 3rem;
}
.row-img img {
  border-radius: 10px;
  box-shadow: rgba(82, 82, 83, 0.2) 0px 7px 29px 0px;
  margin-top: 8rem;
  height: 27rem;
  width: 20rem;
  @media (max-width: 992px) {
    margin-top: 0rem;
    height: 10rem;
  }
}

.sec2 .text {
  text-align: justify;
  font-size: 22px;
  word-spacing: 3px;
  color: rgb(99, 98, 98);
  line-break: 5px;
  position: relative;
  transition: all 500ms ease;
}
.sec2 .text b {
  font-weight: bolder;
  color: #163c4f;
}

/* styling the section 3 */
.sec3 {
  margin-top: 4rem;
  background-image: linear-gradient(
    rgb(252, 252, 252),
    rgb(92, 178, 228),
    rgb(53, 136, 224)
  );
  padding: 1rem 6rem;
}
.container-about {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container-about h1 {
  font-size: 40px;
  color: rgb(32, 106, 143);
  text-shadow: 2px 2px 5px rgb(176, 175, 175);
  font-weight: 600;
  text-transform: uppercase;
}
.key-points-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-top: 3px;
}

.text-sec3 {
  margin-bottom: 3%;
  font-size: 19px;
  color: rgb(90, 90, 90);
  font-weight: 500;
  transition: all 500ms ease;
}

.more-info {
  padding: 2rem;
  margin-top: 2%;
}

/* styling the section 4 mision and vision content */

.statement {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.statement h1 {
  color: #163c4f;
  font-size: 45px;
  text-transform: uppercase;
  font-weight: 600;
  text-shadow: 2px 2px 5px rgb(176, 175, 175);
  font-family: serif;
}
.statement p {
  font-size: 19px;
  color: rgb(53, 53, 53);
  font-weight: 500;
  transition: all 500ms ease;
}
.sec4 {
  margin-top: 0rem;
  padding: 0 6rem;
}
.sec4-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  position: relative;
  margin-top: 1rem;
}
.sec4-content h1 {
  font-size: 30px;
  font-weight: 600;
  color: #163c4f;

  text-shadow: 2px 2px 5px rgb(176, 175, 175);
  font-family: serif;
}
.sec4-content p {
  font-size: 18px;
  line-height: 30px;
  color: rgb(99, 98, 98);
  line-break: 5px;
  position: relative;
  transition: all 500ms ease;
  width: 50%;
}
.about_page_section {
  display: flex;
  gap: 40px;
}
@media (max-width: 768px) {
  .sec2 {
    padding: 0 2rem;
    display: flex;
    margin-top: 3rem;
  }
  .row {
    flex-direction: column;
    gap: 20px;
  }
  .row-info h1 {
    font-size: 30px;
    text-align: center;
  }
  .sec2 .text {
    font-size: 16px;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
  }
  .sec3 {
    padding: 1rem 2rem;
  }
  .container-about h1 {
    font-size: 30px;
  }
  .key-points-info {
    flex-direction: column;
    gap: 5px;
  }
  .text-sec3 {
    font-size: 16px;
  }
  .more-info {
    padding: 1rem;
  }
  .statement h1 {
    font-size: 30px;
  }
  .statement p {
    font-size: 16px;
  }
  .sec4 {
    padding: 0 2rem;
  }
  .sec4-content h1 {
    display: flex;
    font-size: 25px;
    justify-content: center;
  }
  .sec4-content p {
    display: inline;
    font-size: 16px;
    justify-items: center;
    text-align: center;
  }
  .sec4-info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .row-img img {
    width: 100%;
  }
  .typo2 {
    margin-top: 60%;
    margin-left: 2rem;
  }
  .about_page_section {
    display: block;
  }
  .about_page_section h2 {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
.about_para {
  display: flex;
  flex-direction: column;
  width: 900px;
}
.about_para p {
  color: rgb(99, 98, 98);
  font-size: 18px;
  margin-bottom: 0.5rem;
  font-family: serif;
}
.about_para b {
  color: #163c4f;
  font-weight: bolder;
  transform: scale(1.5);
  margin-right: 6px;
  margin-top: 2px;
  display: inline-block;
  height: auto;
}
.about_image img {
  width: 380px;
  border-radius: 4rem;
  box-shadow: 1px 4px 10px rgb(160, 160, 160);
  @media (max-width: 992px) {
    width: 320px;
  }
}
.about_content {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 60px;
  margin-left: 5rem;
  margin-right: 5rem;
  @media (max-width: 992px) {
    flex-direction: column-reverse;
    gap: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
.about_heading {
  color: #163c4f;
  font-size: 45px;
  text-transform: uppercase;
  font-weight: 600;
  text-shadow: 2px 2px 5px rgb(176, 175, 175);
  font-family: serif;
  transition: opacity 0.5s ease-in;
}
.about_heading-visible {
  opacity: 1;
  animation: slideIn 2s linear;
}
/* @keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(10%);
  }
  to {
    opacity: 1;
    transform: translateX(100%);
  }
} */
.core-header {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  align-items: center;
}
.span-text h1 {
  color: #163c4f;
  font-size: 22px;
  margin-bottom: 0.3rem;
  animation: slideInLeft 3s forwards infinite;
}
.span-text-left h1 {
  animation: slideIn 3s forwards infinite;
  color: #163c4f;
  font-size: 22px;
  margin-bottom: 0.3rem;
}
.about-info-box {
  display: none;
  flex-direction: row;
  justify-content: center;
  position: relative;
  height: 15rem;
  @media (max-width: 992px) {
    width: 100%;
    flex-wrap: wrap;
    height: auto;
  }
}
.about-core-img {
  @media (max-width: 992px) {
    width: 100%;
  }
}
.core-header {
  @media (max-width: 768px) {
    display: none;
  }
}
.box .section4-box {
  @media (max-width: 992px) {
    overflow-x: scroll;
  }
}
.about-info-box::before {
  content: "";
  background-image: url("../Images//box-img.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.2;
  position: absolute;
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .about_para {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .about_para p {
    font-size: 16px;
  }
  .about_para b {
    transform: scale(1.2);
    margin-right: 2px;
    margin-top: 2px;
  }
  .about_heading {
    font-size: 25px;
  }
}

/* service container */

.service-five {
  padding-bottom: 120px;
  padding-top: 120px;
  position: relative;
  @media (max-width: 992px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.service-five .pattern-layer {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  left: 0;
  top: 0;
  right: 0;
  position: absolute;
}

.auto-container {
  position: static;
  max-width: 100%;
  padding: 0px 15px;
  margin: 0 auto;
  margin-top: 3rem;
  display: flex;
  justify-content: flex-start;
}
.service-block {
  -ms-flex: 0 0 17%;
  flex: 0 0 17%;
  max-width: 17%;
}
.service-block-five .inner-box {
  overflow: hidden;
  background: transparent;
  min-height: 200px;
  margin-bottom: 30px;
  border: 1px solid #fff;
  border-radius: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
}
.service-block-five .inner-box:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 0%;
  background: #0a446c;
  left: 0px;
  bottom: 0px;
  transition: all 500ms ease;
}
.service-block-five .inner-box .icon-box {
  color: #fff;
  margin-bottom: 25px;
  line-height: 45px;
  font-size: 45px;
  transition: 0.5s;
  position: relative;
  z-index: 1;
}
.service-block-five .inner-box h3 {
  font-size: 18px;
  margin-bottom: 20px;
  line-height: 30px;
  font-weight: 700;
  display: block;
  font-family: "Frank Ruhl Libre", serif;
  position: relative;
  z-index: 1;
}
.fal {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
}
.service-block-five .inner-box h3 a {
  color: #fff;
  text-decoration: none;
}
.clearfix {
  display: block;
  flex-wrap: wrap;
  width: 100%;
  height: 80%;
  justify-content: space-between;
  gap: 40px;
  @media (max-width: 992px) {
    margin: 0px !important;
    gap: 12px !important;
    width: 100%;
  }
}
.card-clearfix {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 80%;
  gap: 40px;
  @media (max-width: 992px) {
    margin: 0px !important;
    gap: 12px !important;
    width: 100%;
  }
}
.home-auto-container {
  margin-top: 0 !important;
}
.service-block-five .inner-box:hover:before {
  height: 100%;
  top: 0px;
  z-index: 0;
}
.service-block-five .inner-box:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 0%;
  background: #0a446c;
  left: 0px;
  bottom: 0px;
  transition: all 500ms ease;
  z-index: 0;
}
@media (max-width: 992px) {
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
    position: relative;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    position: relative;
    width: 100%;
  }
}

@media (max-width: 576px) {
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    width: 100%;
  }
}
