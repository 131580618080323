.typo3 {
  white-space: nowrap;
  margin-left: 5%;
  margin-top: 5%;
}

.audit-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
  gap: 20px;
}
@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
.info-paragraphs {
  width: 55%;
  overflow: hidden;
  transition: opacity 0.5s ease-in;
}
.info-paragraphs.visible {
  opacity: 1;
  animation: slideIn 1s linear;
}
.audit-img img {
  border-radius: 4px;
  box-shadow: 1px 4px 10px rgb(160, 160, 160);
}
.info-paragraphs .text {
  position: relative;
  font-size: 16px;
  font-weight: 400;
  color: rgb(99, 98, 98);
  font-family: "Open Sans", Helvetica, Arial, Lucida, sans-serif;
}

.audit-key-points-heading h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  font-size: 30px;
  color: rgb(32, 106, 143);
  text-shadow: 2px 2px 5px rgb(176, 175, 175);
  font-weight: 600;
  text-transform: uppercase;
}
.audit-key-points-info {
  display: flex;
  flex-direction: row;
  padding-left: 6rem;
  align-items: center;
  gap: 10px;
}

.audit-key-points-info .text {
  font-size: 19px;
  font-weight: 400;
  color: rgb(8, 8, 8);
}
.bookeeping-container-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4rem;
}
.bookkeeping-info h2 {
  font-size: 36px;
  color: rgb(32, 106, 143);
  text-shadow: 2px 2px 5px rgb(176, 175, 175);
  font-weight: 600;
}
.bookkeeping-info strong {
  font-size: 19px;
}

@media screen and (max-width: 768px) {
  .typo3 {
    margin-top: 60%;
    margin-left: 2rem;
  }

  .audit-info-container {
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    flex-direction: column;
  }
  .audit-img img {
    width: 100%;
    justify-content: center;
  }

  .audit-key-points-heading h1 {
    margin-top: 2rem;
    font-size: 22px;
  }
  .info-paragraphs {
    width: 100%;
  }
  .audit-key-points-info {
    flex-direction: column;
    padding-left: 0;
  }
  .audit-key-points-info .text {
    font-size: 16px;
  }
  .bookeeping-container-2 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .bookkeeping-info h2 {
    font-size: 30px;
  }
}
