@import url("https://fonts.googleapis.com/css2?family=Catamaran&display=swap");
* {
  padding: 0;
  margin: 0;
  font-family: "Catamaran", sans-serif;
}
/* styling the headline ticker */
/* Add this CSS for dropdown menu */
.services-dropdown {
  align-items: center;
  text-align: center;
  justify-content: flex-start;
  text-decoration: none;
  font-size: 22px;
  font-weight: 500;
  padding: 6px 12px;
  color: rgb(250, 250, 250);
}

.services-dropdown .dropdown-content {
  display: none;
  position: absolute;
  background-color: rgba(11, 125, 187, 0.445);
  backdrop-filter: blur(10px);
  border-radius: 4px;
  min-width: 160px;
  z-index: 1;
}

.services-dropdown:hover .dropdown-content {
  display: block;
  right: 10px;
}

.services-dropdown .dropdown-content a {
  color: white;
  padding: 10px 14px;
  text-decoration: none;
  display: block;
  font-size: 14px;
}

.services-dropdown .dropdown-content a:hover {
  background-color: #0a446c;
}

.headline-box {
  width: 100%;
  float: left;
  margin: 0px auto 0 auto;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0;
  position: relative;
  background: #0a446c;
  margin-bottom: 0rem;
}
.box-animation {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  padding: 8px 0 8px 100%;
  display: inline-block;
  animation: headline 15s infinite linear;
}
@keyframes headline {
  0% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.headline-box span div {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  margin: 0 30px;
  width: auto;
  display: inline-block;
  letter-spacing: 1px;
  text-transform: uppercase;
}

/* styling the navbar */

* {
  box-sizing: border-box;
}
body {
  margin: 0%;
  padding: 0%;
  background-color: rgb(250, 247, 247);
}

.nav-content {
  display: flex;
  justify-content: space-between;
  position: static;
  flex-direction: row;
  align-items: center;
  padding-right: 80px;
  border-bottom: 1px white solid;
  border-top: 1px solid white;
  background-color: rgb(4, 1, 37);
  height: 7rem;
  z-index: 10;
}

.nav-toggle {
  display: none;
}

.list {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  width: 100%;
  height: 100%;
}
.list a {
  align-items: center;
  text-align: flex-end;
  justify-content: flex-end;
  text-decoration: none;
  font-size: 22px;
  font-weight: 500;
  padding: 6px 12px;
  color: rgb(250, 250, 250);
}
.list a:hover {
  color: rgb(35, 155, 215);
}

.logo {
  width: 12%;
  margin-left: 2rem;
  @media (max-width: 992px) {
    margin-left: 0;
    padding-bottom: 10px;
  }
}
.logo img {
  width: 10rem;
  height: auto;
}
@keyframes anim-lineUp {
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  20% {
    opacity: 1;
    transform: translateY(0%);
  }
  0% {
    opacity: 1;
    transform: translateY(-60%);
  }
}
@keyframes anim-lineUp-btn {
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  20% {
    opacity: 1;
    transform: translateY(0%);
  }
  0% {
    opacity: 1;
    transform: translateY(-40%);
  }
}

.info h1 {
  font-size: 4rem;
  text-align: right;
  color: rgb(250, 250, 250);
  font-weight: 600;
  padding-right: 8rem;
  padding-top: 14%;
  display: block;
}
.info span {
  font-size: 1.4rem;
  text-align: right;
  color: rgb(250, 250, 250);
  font-weight: 600;
  padding-right: 8rem;
  display: block;
  animation: 4s anim-lineUp ease-out;
}

.btn {
  padding: 14px 28px;
  color: rgb(250, 250, 250);
  background-color: #0a446c;
  font-size: 18px;
  border-radius: 4px;
  border: none;
  animation: 4s anim-lineUp-btn ease-out;
  margin-top: 1rem;
  margin-right: 8rem;
}

.btn:hover {
  background-color: rgb(49, 193, 49);
}

.logo {
  justify-content: flex-start;
  margin-left: 5rem;
  width: 12%;
  @media (max-width: 992px) {
    margin-left: 0;
  }
}

/* styling the footer */

.whatsapp-icon {
  display: flex;
  justify-content: left;
  padding: 6px;
}
.whatsapp-icon img {
  position: fixed;
  bottom: 1rem;
}

.footer-sec {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: baseline;
  text-align: start;
  height: 100%;
  width: 100%;
  background-color: #22bbc2;
  padding: 2rem;
  margin-top: 2rem;
}
.company {
  display: flex;
  flex-direction: column;
  width: 35%;
  gap: 15px;
  color: #fff;
}
.company p {
  font-size: 17px;
  font-weight: 400;
  line-height: 22px;
  cursor: pointer;
  color: #ffffff;
}
.footer-links {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
.footer-links a {
  text-decoration: none;
  color: #ffffff;
  font-size: 17px;
  line-height: 30px;
  font-weight: 400;
}
.footer-links a:hover {
  color: rgb(35, 155, 215);
}
.links {
  display: flex;
  flex-direction: column;
}
.footer-heading {
  color: #fff;
  font-size: 22px;
  font-weight: 400;
  position: relative;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.footer-heading::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0px;
  background-color: rgb(35, 155, 215);
  height: 2px;
  width: 40px;
  margin-top: 2rem;
}
.follow-links img {
  padding: 10px;
  cursor: pointer;
}
.footer-sec-container {
  background-color: #22bbc2;
}
.copyright {
  background-color: #333; /* Dark background color */
  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  padding: 10px; /* Spacing around text */
  font-size: 14px; /* Font size */
  left: 0;
  bottom: 0;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .footer-sec {
    flex-direction: column;
  }
  .company {
    width: 100%;
  }
  .footer-links {
    margin-top: 2rem;
    width: 100%;
  }
  .footer-links a {
    font-size: 15px;
  }
  .footer-heading {
    margin-top: 2rem;
  }
  .footer-heading::before {
    width: 30px;
  }
  .follow-links a {
    padding: 10px;
  }
  .container {
    height: 100vh;
    background-image: 100%;
    flex-direction: column;
  }
  .info {
    padding-top: 20%;
    text-size-adjust: 20px;
  }
  .btn {
    margin-left: 50%;
  }
  .nav {
    display: none;
  }

  .logo {
    width: 40%;
    margin-top: 2%;
    margin-left: 180px;
    justify-content: center;
    @media (max-width: 992px) {
      margin-top: 0;
      margin-left: 0;
    }
  }
  .nav-content {
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  .hamburger-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: absolute;
    background-color: rgba(11, 125, 187, 0.445);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 4px;
    min-width: 100px;
    z-index: 1;
    padding: 4% 8%;
    margin-top: 10%;
    height: auto;
  }

  .hamburger-menu a {
    align-items: center;
    text-align: flex-start;
    justify-content: flex-start;
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    padding: 6px 12px;
    color: rgb(250, 250, 250);
  }
  .hamburger-menu a:hover {
    color: rgb(35, 155, 215);
  }

  .hamburger-menu .servi {
    font-size: x-small;
  }

  .headline-box {
    display: flex;
    flex-direction: column;
    margin-top: -189%;
  }

  .nav.open {
    max-height: 1000px; /* Adjust the max height as needed */
  }

  .nav-toggle {
    display: block;
    cursor: pointer;
    font-size: 1.5rem;
    color: #fff;
    margin-left: 25px;
    margin-top: -19%;
  }

  .list a {
    padding: 10px 20px;
  }
}

/* new */
.page-template-front-page .flat_header_wrap.header-style4 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
}
.header {
  position: absolute;
  width: 100%;
  z-index: 101;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  background-color: transparent;
  @media (max-width: 992px) {
    display: none;
  }
}
.ismobilenav {
  display: none;
  @media (max-width: 992px) {
    display: flex;
  }
}
.ismobilenav2 {
  display: none;
  @media (max-width: 992px) {
    display: flex;
  }
}
.collapse.show {
  background-color: #1d2738;
}
.navbar-light .navbar-nav .nav-link {
  text-align: left;
  border-top: 1px solid #2d374a;
  cursor: pointer;
  color: white !important;
  padding: 15px 15px;
  width: 100%;
  line-height: 10px;
}
.navbar-collapse {
  background-color: #1d2738;
}
.navbar {
  @media (max-width: 992px) {
    padding: 0px !important;
  }
}
.navbar-toggler {
  border: none;
}
.nav-item {
  height: 40px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  width: 100%;
}
.nav-item-2 {
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  background: #29307b;
}
.navbar-dark .navbar-nav .nav-link {
  color: white;
}
.item-name {
  font-size: 13px;
  text-align: center;
  font-weight: 700;
  text-transform: capitalize;
}
.navbar-toggler:focus {
  box-shadow: none;
}
.dropdown-toggle::after {
  position: absolute;
  right: 15px;
  top: 15px;
}
.dropdown-menu {
  animation: 0.5s slideup;
}
.container-fluid-navbar {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: end;
  width: 100%;
}
.dropdown-item-1 {
  display: flex;
  width: 100%;
  padding: 1.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-family: system-ui;
  font-size: 13px;
  font-weight: 700;
  text-transform: capitalize;
  color: #000;
}
.second-nav-button {
  padding: 16px 10px;
}
@keyframes slideup {
  from {
    transform: translateY(10%);
  }

  to {
    transform: translateY(0);
  }
}
.navbar-nav .dropdown-menu {
  position: absolute !important;
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1200px;
  }
}
.container::before {
  display: table;
  content: " ";
}
.container::after {
  clear: both;
}
.row {
  margin-right: -15px;
  margin-left: -15px;
}
/* .row::before {
  display: table;
  content: " ";
} */
.row::after {
  clear: both;
}
.top-search {
  position: absolute;
  width: 250px;
  right: 15px;
  top: 100px;
  opacity: 0;
  visibility: hidden;
  z-index: -99;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.widget_search .search-form {
  width: 100%;
  height: 50px;
  position: relative;
  margin: 0;
  z-index: 9999;
}
.widget_search .search-form label {
  display: block;
}
.search-form label {
  width: 100%;
  background-color: #fff;
}
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}
.widget_search .search-form .search-submit {
  /* background-image: url('../')); */
  background-color: blue;
  background-size: 13px 13px;
  background-position: center right;
  background-repeat: no-repeat;
  width: 24px;
  height: 21px;
  border-left: 1px solid rgba(0, 0, 0, 0.4);
  padding: 5px 0 5px 10px;
  text-indent: -9999px;
  padding: 0;
  position: absolute;
  right: 15px;
  top: 12px;
  -webkit-opacity: 0.3;
  -khtml-opacity: 0.3;
  -moz-opacity: 0.3;
  opacity: 0.3;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=30);
  filter: alpha(opacity = 30);
}
#mainnav > ul > li > a {
  line-height: 92px;
  display: inline-block;
  padding: 0 20px 0 20px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
}
@media (max-width: 992px) {
  .col-md-12 {
    width: 100%;
  }
}
@media (min-width: 992px) {
  float: left;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.header .header-wrap .logo {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  float: left;
}
.nav-wrap {
  float: right;
  display: flex;
  justify-content: end;
  width: 60%;
}
.btn-menu {
  display: none;
  float: right;
  position: relative;
  background: transparent;
  cursor: pointer;
  margin: 38px 0 38px 20px;
  width: 26px;
  height: 16px;
  -webkit-transition: all ease 0.238s;
  -moz-transition: all ease 0.238s;
  transition: all ease 0.238s;
}
#mainnav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#mainnav > ul > li {
  display: inline-block;
}
#mainnav ul li {
  position: relative;
}
.menu-item a:hover {
  color: #1bb9c1 !important;
}
a {
  text-decoration: none;
}

/* Sub Header Css */
.ubermenu.ubermenu-main {
  background: none;
  border: none;
  box-shadow: none;
  display: flex;
  justify-content: center;
  width: 100%;
}
/* @media screen and (min-width: 960px) {
  .ubermenu-responsive-default.ubermenu {
    display: block !important;
  }
} */
.ubermenu-main {
  font-family: sans-serif;
}
.ubermenu-main {
  max-width: 100%;
  margin-top: 10px;
}
.ubermenu-items-align-center {
  text-align: center;
}
.ubermenu-bar-align-full {
  clear: both;
}
.ubermenu {
  overflow: visible !important;
  line-height: 1.3em;
  height: auto;
  width: auto;
  max-height: none;
  min-height: 0;
  margin: 0;
  padding: 0;
  text-indent: 0;
  clip: auto;
  position: static;
  box-sizing: border-box;
  vertical-align: top;
  border-radius: 0;
  pointer-events: auto;
}
.ubermenu:after {
  content: "";
  display: table;
  clear: both;
}

.ubermenu-bound-inner .ubermenu-nav {
  position: relative;
}
.ubermenu .ubermenu-nav {
  height: auto;
  width: 100%;
  max-height: none;
  min-height: 0;
  margin: 0;
  padding: 0;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  text-indent: 0;
  clip: auto;
  background: 0 0;
  text-transform: none;
  box-sizing: border-box;
  vertical-align: top;
  line-height: 1.3em;
  border: none;
  border-radius: 0;
  pointer-events: auto;
  text-align: inherit;
}
.ubermenu .ubermenu-item.ubermenu-item-level-0 {
  float: none;
}
.ubermenu .ubermenu-column,
.ubermenu .ubermenu-row {
  max-width: 100%;
}
.ubermenu .ubermenu-item-level-0 {
  vertical-align: bottom;
  -moz-backface-visibility: visible;
  backface-visibility: visible;
}
.ubermenu .ubermenu-item {
  text-align: left;
  display: inline-block;
  vertical-align: top;
}
.ubermenu .ubermenu-column,
.ubermenu .ubermenu-item {
  height: auto;
  width: auto;
  max-height: none;
  min-height: 0;
}
.ubermenu .ubermenu-column,
.ubermenu .ubermenu-item {
  margin: 0;
  padding: 0;
  text-indent: 0;
  clip: auto;
  position: static;
  background: 0 0;
  text-transform: none;
  overflow: visible;
  box-sizing: border-box;
  line-height: 1.3em;
  border: none;
  border-radius: 0;
  pointer-events: auto;
}
body:not(.rtl)
  .ubermenu.ubermenu-horizontal
  .ubermenu-item-level-0:first-child
  > .ubermenu-target {
  border-left: none;
}
.ubermenu-main.ubermenu-sub-indicators
  .ubermenu-item-level-0.ubermenu-has-submenu-drop
  > .ubermenu-target:not(.ubermenu-noindicator) {
  padding-right: 28px;
}
.ubermenu-main
  .ubermenu-nav
  .ubermenu-item.ubermenu-item-level-0
  > .ubermenu-target {
  font-weight: normal;
}
.ubermenu-main .ubermenu-target,
.ubermenu-main .ubermenu-nav .ubermenu-item-level-0 .ubermenu-target {
  font-family: sans-serif;
}
.ubermenu.ubermenu-main .ubermenu-item-level-0 > .ubermenu-target {
  border: none;
  box-shadow: none;
}
.ubermenu-main .ubermenu-item-level-0 > .ubermenu-target {
  line-height: 45px !important;
}
.ubermenu-main .ubermenu-item-level-0 > .ubermenu-target {
  font-size: 12px;
  text-transform: uppercase;
  color: #ffffff;
  padding-left: 6px;
}
.ubermenu-main .ubermenu-item-level-0 > .ubermenu-target {
  padding-top: 5px;
  padding-bottom: 5px;
}
.ubermenu .ubermenu-target {
  display: block;
  text-decoration: none;
  position: relative;
}
.ubermenu .ubermenu-target-text {
  line-height: 20px;
}
.ubermenu .ubermenu-target-text {
  font-size: 17px;
  width: 126px;
  text-align: center;
  font-weight: bolder !important;
}
.ubermenu .ubermenu-target-text {
  display: inline-block;
  vertical-align: middle;
  font-family: inherit;
  font-weight: bolder;
  color: inherit;
  text-transform: uppercase;
}
svg {
  transform: scale(2.5);
  padding-top: 0px !important;
}

.ubermenu.ubermenu-main.ubermenu-horizontal
  .ubermenu-submenu-drop.ubermenu-submenu-align-full_width {
  left: 0;
}
.ubermenu-main:not(.ubermenu-transition-slide)
  .ubermenu-submenu.ubermenu-submenu-type-mega,
.ubermenu .ubermenu-force > .ubermenu-submenu {
  max-height: 1080px;
}
.ubermenu .ubermenu-item:not(.ubermenu-active) .ubermenu-submenu-drop {
  min-height: 0 !important;
}
.ubermenu-main .ubermenu-submenu.ubermenu-submenu-drop {
  background-color: rgba(255, 255, 255, 1);
}
.ubermenu-main .ubermenu-item-level-0 > .ubermenu-submenu-drop {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}
.ubermenu-transition-shift .ubermenu-item .ubermenu-submenu-drop {
  margin-top: 20px;
  top: auto;
  opacity: 0;
  -webkit-transition-property: margin-top, opacity, visibility;
  -ms-transition-property: margin-top, opacity, visibility;
  transition-property: margin-top, opacity, visibility;
}
.ubermenu .ubermenu-submenu-drop.ubermenu-submenu-align-full_width {
  width: 100%;
}
.ubermenu .ubermenu-item-level-0 > .ubermenu-submenu-drop {
  clip: rect(0, 5000px, 5000px, -5000px);
}
.ubermenu .ubermenu-item .ubermenu-submenu-drop {
  position: absolute;
  z-index: 500;
  height: 0;
  max-height: 0;
  visibility: hidden;
  overflow: hidden;
}
.ubermenu .ubermenu-column::after {
  content: "";
  display: table;
  clear: both;
}
.ubermenu .ubermenu-item {
  text-align: left;
}
@media screen and (min-width: 960px) {
  .ubermenu-responsive-default.ubermenu-responsive.ubermenu-retractors-responsive
    .ubermenu-retractor-mobile {
    display: none;
  }
}
.ubermenu .ubermenu-retractor {
  display: block;
  clear: both;
  text-align: center;
  cursor: pointer;
}
.ubermenu .ubermenu-submenu .ubermenu-column-auto {
  width: 25% !important;
}
.ubermenu .ubermenu-submenu .ubermenu-column-auto {
  min-width: 100px;
}
.ubermenu .ubermenu-submenu .ubermenu-column {
  display: block;
  float: left;
}
.ubermenu .ubermenu-column,
.ubermenu .ubermenu-row {
  max-width: 100%;
}
.ubermenu .ubermenu-item {
  text-align: left;
  display: inline-block;
  vertical-align: top;
  width: 10.28%;
}
.ubermenu-main .ubermenu-target,
.ubermenu-main .ubermenu-nav .ubermenu-item-level-0 .ubermenu-target {
  font-family: sans-serif;
}
.ubermenu-main .ubermenu-submenu .ubermenu-item-header > .ubermenu-target {
  font-size: 13px;
  text-transform: uppercase;
}
.ubermenu a.ubermenu-target {
  cursor: pointer;
}
.ubermenu .ubermenu-target {
  display: block;
  text-decoration: none;
  position: relative;
}
.ubermenu .ubermenu-target {
  padding: 15px 20px;
  height: auto;
  width: auto;
  max-height: none;
  min-height: 0;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  text-indent: 0;
  clip: auto;
  box-sizing: border-box;
  vertical-align: top;
  line-height: 1.3em;
  border: none;
  border-radius: 0;
  pointer-events: auto;
}
.ubermenu .ubermenu-submenu .ubermenu-column-auto a span {
  width: 100% !important;
  height: 40px;
  text-align: left;
}
.ubermenu .ubermenu-target-text {
  line-height: 20px;
}
.ubermenu .ubermenu-target-text {
  font-size: 15px;
  width: 126px;
  text-align: center;
  font-weight: 700;
}
.ubermenu .ubermenu-target-text {
  display: inline-block;
  vertical-align: middle;
  font-family: bold;
  font-weight: inherit;
  color: inherit;
  width: 90%;
}
.submenu {
  display: none;
  /* need to remove */
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  background-color: white;
  border-radius: 1rem;
}
.submenu span {
  color: black;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: bolder;
  text-transform: uppercase;
}

.submenu a {
  color: black;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: bolder;

  text-transform: uppercase;
}
.ubermenu-has-submenu-mega:hover {
  .submenu {
    display: block;
    position: absolute;
    width: 100%;
    background-color: white;
  }
  .submenu span:hover {
    color: #1bb9c1;
  }
  .submenu a:hover {
    color: #1bb9c1;
  }
  /* .test {
    display: block;
  } */
}

.mainBox {
  width: 100%;
}

.footer-logo {
  margin-top: 15px;
  margin-bottom: 0px;
  transition: all 0.3s ease-in-out;
  float: left;
  width: 12%;
}
.footer-logo img {
  width: 10rem;
  height: auto;
  opacity: 0.8;
}

.accordion-button.collapsed {
  background: white;
}

.accordion-button.collapsed::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
